.App {
  text-align: center;
}

.App-logo {
  max-height: 500px;
  height: 275px;
  align-items: center;
}

.App-header{
  padding: 0.5px;
  left: 0;
  bottom: 0;
  width: 100%;
  background-image: linear-gradient(to right, #9489f0, #854b8d, #7e7dc2, #f37575);
  color: white;
  font-size: 14px;
  text-align: center;
}

a{
  color: hotpink;
  text-decoration: none;
  font-weight: bold;
  font-size: 20px;
}

a:hover{
  font-size: 22px;
}

.hide{
  transform: scale(0);
}
