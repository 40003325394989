.products{
    width: 75%;
    margin-left: auto;
    margin-right: auto;
    align-items: center;
    justify-content: center; 
}
  
.products_title{
    background: -webkit-linear-gradient(#eee, #c20395);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    /*text-decoration: underline #570243;*/
    text-decoration:underline #c20395;
}
  
  
.products img{
    max-width: 375px;
    max-height: 425px;
}
  
  @media screen and (max-width:600px) {
  .products img{
      max-width: 300px;
      max-height: 400px;
    }
  }
  
.heladosbanner{
    width: 100%;
    margin-left: auto;
    margin-right: auto;
    align-items: center;
    justify-content: center;
}

.heladosbanner img{
  max-width: 385px;
  max-height: 800px;
}

  button {
    font-family: 'Roboto', sans-serif;
    display: inline-block;
    position: relative;
    border-radius: 12px;
    text-decoration: none;
    padding: 0.5em;
    margin: 0.5em;
    font-size: 2em;
    font-weight: bold;
    transition: all .5s;
    width: 250px;
    -webkit-background-clip: text;
    color: white;
  }
  
  .b-pink, .b-pink:before {
    background: rgba(231,72,234,1);
    background: -moz-linear-gradient(45deg, rgba(231,72,234,1) 0%, rgba(75,26,188,1) 100%);
    background: -webkit-gradient(left bottom, right top, color-stop(0%, rgba(231,72,234,1)), color-stop(100%, rgba(75,26,188,1)));
    background: -webkit-linear-gradient(45deg, rgba(231,72,234,1) 0%, rgba(75,26,188,1) 100%);
    background: -o-linear-gradient(45deg, rgba(231,72,234,1) 0%, rgba(75,26,188,1) 100%);
    background: -ms-linear-gradient(45deg, rgba(231,72,234,1) 0%, rgba(75,26,188,1) 100%);
    background: linear-gradient(45deg, rgba(231,72,234,1) 0%, rgba(75,26,188,1) 100%);
    filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#e748ea', endColorstr='#4b1abc', GradientType=1 );
  }
  
  .button:after {
    background: #2c3e50;
  }
  .ctn{
    display: block;
    margin: auto;
    text-align: center;
  }
  