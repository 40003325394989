.contact_title{
    background: -webkit-linear-gradient(#eee, #E60A9A);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    text-decoration: underline #E60A9A;
}

.contact{
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center; 
}

@media screen and (max-width:600px) {
    .contact{
    display: inline;
    flex-direction: column;
    align-items: center;
    justify-content: center; 
    }
}

.contact img{
    max-width: 500px;
}

.contact h1{
    color: #3DE0F2;
}

.contact table{
    align-self: center;
    text-align: left;
}

.contact td{
    height: 300px;
}

.row_info{
    display: flex;
    align-items: center;
    justify-content: left;
}

.row_info img{
    width: 40px;
    height: 40px;
}
