.who{
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;   
  
  }
  
  .who_title{
    background: -webkit-linear-gradient(#eee, #e258b2);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    text-decoration: underline #e663b8;
  }
  
  .who img{
    width: 200px;
    height: 200px;
    max-width: 250px;
    max-height: 250px;
  }
  
  .who p{
    font-size: 16px;
    width: 75%;
  }
  