.footer {
    /*position: fixed;*/
    padding: 0.5px;
    left: 0;
    bottom: 0;
    width: 100%;
    /*background-color: #b9077e;*/
    background-image: linear-gradient(to right, #6253e1, #852D91, #A3A1FF, #F24645);
    color: white;
    font-size: 14px;
    text-align: center;
}
